import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";
import "../theme/global.css";
import {
  Avis,
  ProductList,
  FormHero,
  Hero,
  Layout,
  ListCheck,
  Separator,
  StickyBar,
  Text,
  TextImg,
  Title,
  Wrapper,
} from "../components";
import { Helmet } from "react-helmet";
import _ from "lodash";
import ImgNuisible from "../assets/domain/nuisible.jpg";
import styled from "styled-components";
import breakpoint from "../theme/breakpoints";
import ArrowLink from "../assets/svg/arrow_link.svg";

export const query = graphql`
  query ($id: String!, $ville: String!) {
    nuisibleJson(id: { eq: $id }) {
      id
      title
      ville
      code_postal
      description
      h1
      h1_contenu_html
      h2_1_html
      h2_1_contenu_html
      h2_2_html
      h2_2_contenu_html
      h2_3_html
      h2_3_contenu_html
      h2_4_html
      h2_4_contenu_html
      h2_5_html
      h2_5_contenu_html
      h2_6
      h2_6_html
      h2_6_contenu_html
      h2_7
      h2_7_html
      h2_7_contenu_html
      h2_8
      h2_8_html
      h2_8_contenu_html
      h2_9
      h2_9_html
      h2_9_contenu_html
    }
    allCitiesJson(
      filter: {
        name: { ne: $ville }
        zip_code: {
          in: [
            "06700"
            "13600"
            "22000"
            "24000"
            "26100"
            "28100"
            "30100"
            "31770"
            "35400"
            "37300"
            "40000"
            "44400"
            "44800"
            "47000"
            "50100"
            "54500"
            "59400"
            "59600"
            "60100"
            "62800"
            "67300"
            "69000"
            "69140"
            "69330"
            "71000"
            "74100"
            "74200"
            "76200"
            "78130"
            "78180"
            "78190"
            "78370"
            "78400"
            "78800"
            "83700"
            "86100"
            "88000"
            "89000"
            "91120"
            "91170"
            "91200"
            "92210"
            "92240"
            "93390"
            "94130"
            "94220"
            "94240"
            "94320"
            "95190"
            "95300"
            "75000"
            "31000"
            "06000"
            "44000"
            "34000"
            "59000"
            "35000"
            "76600"
            "42000"
            "83000"
            "49000"
            "30000"
            "13080"
            "29200"
            "37000"
            "92100"
            "76000"
            "54100"
            "93100"
            "86000"
            "92400"
            "92600"
            "64000"
            "34500"
            "44600"
            "29000"
            "26000"
            "69200"
            "60155"
            "83400"
            "49300"
            "13104"
            "83600"
            "93150"
            "06130"
            "74000"
            "81990"
            "64100"
            "59300"
            "34200"
            "81100"
            "62000"
            "69120"
            "69300"
            "60200"
            "92220"
            "64600"
            "42400"
            "83140"
            "92320"
            "13000"
            "67000"
            "33000"
            "51100"
            "38000"
            "21000"
            "25000"
            "95100"
            "59100"
            "59140"
            "59200"
            "06600"
            "62100"
            "06400"
            "83500"
            "95200"
            "33600"
            "94200"
            "73000"
            "79000"
            "56100"
            "95000"
            "82000"
            "02100"
            "13117"
            "13400"
            "71100"
            "62200"
            "65000"
            "78200"
            "13118"
            "06110"
            "91300"
            "59500"
            "69800"
            "93190"
            "16000"
            "59150"
            "92230"
            "93110"
            "57100"
            "94600"
            "33400"
            "28000"
            "42300"
            "83300"
            "38400"
            "38130"
            "26200"
            "67500"
            "69100"
            "72000"
            "63000"
            "87000"
            "80000"
            "57000"
            "66000"
            "45000"
            "68100"
            "14000"
            "94500"
            "17000"
            "94100"
            "68000"
            "93700"
            "18000"
            "33700"
            "92130"
            "92300"
            "59491"
            "94700"
            "77500"
            "85000"
            "92140"
            "56000"
            "91090"
            "78500"
            "11100"
            "53000"
            "77100"
            "06800"
            "92150"
            "41000"
            "36000"
            "51000"
            "92190"
            "92800"
            "94140"
            "13300"
            "91100"
            "93120"
            "78300"
            "91600"
            "58000"
            "93420"
            "77340"
            "78700"
            "69400"
            "62300"
            "13127"
            "93200"
            "94000"
            "84000"
            "92000"
            "78000"
            "94400"
            "92700"
            "93600"
            "92500"
            "93300"
            "93160"
            "92160"
            "92200"
            "10000"
            "92110"
            "94800"
            "93800"
            "93500"
            "93140"
            "94120"
            "27000"
            "90000"
            "93270"
            "08090"
            "19100"
            "92120"
            "94300"
            "93000"
            "11000"
            "93400"
            "78100"
            "01000"
            "95140"
            "05000"
            "93130"
            "77000"
            "93220"
            "59700"
            "69500"
            "03100"
            "93290"
            "13700"
            "91700"
            "93170"
            "93240"
            "93330"
            "95130"
            "94170"
            "94190"
            "92290"
          ]
        }
      }
    ) {
      edges {
        node {
          code_insee
          department_code
          gps_lat
          gps_lng
          id
          name
          slug
          zip_code
        }
      }
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  line-height: 1.6rem;
  &:hover {
    color: red;
  }
  &:visited {
    color: ${(props) => props.theme.colors.primary}!important;
  }
`;

const StyledLinkBtn = styled.a`
  width: 100%;
  max-width: 456px;
  height: 100%;
  display: block;
  margin: 10px 0;
  padding: 0.8rem 0.4rem;
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  color: black;
  position: relative;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.5s;
  position: relative;

  svg {
    vertical-align: middle;
    fill: ${(props) => props.theme.colors.hsRed};
    margin-left: 0.6rem;
  }

  span {
    font-weight: bold;
  }

  &.red {
    padding: 0.8rem 1rem;
    background-color: ${(props) => props.theme.colors.hsRed};
    color: white;
    font-size: 1rem;

    svg {
      fill: white;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.darkenHsRed};
    }
  }
  &.disabled {
    padding: 0.8rem 1rem;
    background-color: ${(props) => props.theme.colors.customGrayLight};
    color: black;
    font-size: 1.5rem;
    cursor: default;
  }

  @media only screen and ${breakpoint.device.xs} {
    max-width: 90%;
  }
`;

const StyledContent = styled.div`
  max-width: 800px;
`;

const StyledCitiesList = styled.div`
  columns: 2 auto;
  max-width: 650px;
  border: 1px solid #d1d7db;
  border-radius: 4px;
  padding: 1rem 0.8rem 1.2rem 0.8rem;

  @media only screen and ${breakpoint.device.sm} {
    columns: 1;
  }

  @media only screen and ${breakpoint.device.xs} {
    columns: 1;
  }
`;

const StyledArrow = styled(ArrowLink)`
  width: 24px;
  height: 17px;
  color: white;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const options = [
  { value: "plomberie", label: "Plomberie" },
  { value: "electricite", label: "Électricité" },
  { value: "gaz", label: "Gaz" },
  { value: "electromenager", label: "Électroménager" },
  { value: "nuisibles", label: "Dératisation" },
  { value: "nuisibles", label: "Destruction de nids de guêpes" },
];

const defaultValue = { value: "nuisibles", label: "Dératisation" };

const Nuisible = ({ data: { nuisibleJson, allCitiesJson } }) => {
  const cp = nuisibleJson.code_postal.substr(0, 2);
  const [pageUrl, setPageUrl] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setPageUrl(window.location.href);
    }
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>{nuisibleJson.title}</title>
        <meta
          name="description"
          content={`${nuisibleJson.description}`}
          data-react-helmet="true"
        />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Hero title={`<h1>${nuisibleJson.h1}</h1>`}>
        <FormHero options={options} defaultValue={defaultValue} />
      </Hero>
      <StickyBar defaultValue={defaultValue} options={options} />
      <ListCheck />
      <TextImg
        title=""
        text={nuisibleJson.h1_contenu_html}
        image={ImgNuisible}
      />

      <Wrapper>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <StyledLinkBtn
            className="red"
            href="https://depannage.homeserve.fr/produit/deratisation-desourisation?utm_source=geoloc-SEO&utm_medium=deratisation&utm_campaign=interieur"
          >
            <StyledArrow />
            Dératisation intérieur
          </StyledLinkBtn>
          <StyledLinkBtn
            className="red"
            href="https://depannage.homeserve.fr/produit/deratisation-desourisation-exterieur?utm_source=geoloc-SEO&utm_medium=deratisation&utm_campaign=exterieur"
          >
            <StyledArrow />
            Dératisation extérieur
          </StyledLinkBtn>
        </div>
        <StyledContent>
          <Text
            title={nuisibleJson.h2_1_html}
            text={nuisibleJson.h2_1_contenu_html}
          />
        </StyledContent>
      </Wrapper>
      <ProductList name="seo-nuisible" />
      <Wrapper>
        <StyledContent>
          <Text
            title={nuisibleJson.h2_2_html}
            text={nuisibleJson.h2_2_contenu_html}
          />
          <Text
            title={nuisibleJson.h2_3_html}
            text={nuisibleJson.h2_3_contenu_html}
          />
        </StyledContent>
      </Wrapper>
      <Separator />
      <Wrapper>
        <StyledContent>
          <Text
            title={nuisibleJson.h2_4_html}
            text={nuisibleJson.h2_4_contenu_html}
          />
          <Text
            title={nuisibleJson.h2_5_html}
            text={nuisibleJson.h2_5_contenu_html}
          />
          <Text
            title={nuisibleJson.h2_6_html}
            text={nuisibleJson.h2_6_contenu_html}
          />
          <Text
            title={nuisibleJson.h2_7_html}
            text={nuisibleJson.h2_7_contenu_html}
          />
          <Text
            title={nuisibleJson.h2_8_html}
            text={nuisibleJson.h2_8_contenu_html}
          />
          <Text
            title={nuisibleJson.h2_9_html}
            text={nuisibleJson.h2_9_contenu_html}
          />
        </StyledContent>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            paddingBottom: `20px`,
          }}
        >
          <StyledLinkBtn
            className="red"
            href="https://depannage.homeserve.fr/produit/deratisation-desourisation?utm_source=geoloc-SEO&utm_medium=deratisation&utm_campaign=interieur"
          >
            <StyledArrow />
            Dératisation intérieur
          </StyledLinkBtn>
          <StyledLinkBtn
            className="red"
            href="https://depannage.homeserve.fr/produit/deratisation-desourisation-exterieur?utm_source=geoloc-SEO&utm_medium=deratisation&utm_campaign=exterieur"
          >
            <StyledArrow />
            Dératisation extérieur
          </StyledLinkBtn>
        </div>
      </Wrapper>
      <Separator />
      {allCitiesJson.edges.filter((city) =>
        city.node.department_code.substr(0, 2).includes(cp),
      ).length > 0 && (
        <Wrapper>
          <Title>
            <h2>
              Nous intervenons également dans d'autres villes proches de vous
            </h2>
          </Title>
          <StyledCitiesList>
            {allCitiesJson.edges
              .filter((city) => city.node.zip_code.substr(0, 2).includes(cp))
              .map((city, index) => {
                return (
                  <div key={index}>
                    <StyledLink to={`/${_.kebabCase(city.node.slug)}`}>
                      - {city.node.name}
                    </StyledLink>
                  </div>
                );
              })}
          </StyledCitiesList>
        </Wrapper>
      )}
      <Avis />
    </Layout>
  );
};

export default Nuisible;
